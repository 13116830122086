import { Injectable } from '@angular/core';
import { StudentService } from '../StudentO/Services/student.service'
import { ThrowStmt } from '@angular/compiler';
import { AddUserService } from '../UserDetailsO/AddUser/adduser.service';
import { SchedulingService } from '../CollectFeeO/createscheduling/create-scheduling.service'
import { ConfirmDialogService } from '../shared/confirm-dialog/confirm-dialog.service';
import {AccessControlService} from '../MasterSettingO/rollAccess/access-control.service'
import {MasterServiceService} from '../MasterSettingO/Services/master-service.service'
import { AllApisService } from '../all-apis.service';
import { BasicUserDetail } from '../shared/models/basic-user-detail.model';
import { BasicStudentDetail } from '../shared/models/basic-student-detail.model';
import { BasicSchoolDetail } from '../shared/models/basic-school-detail.model';
import { ConcessionModule } from '../ConcessionO/concession.module';


@Injectable({
  providedIn: 'root'
})
export class OneStorage {
  
  allUserCatagories: Array<String> = ["Admission Fee", "Tution Fee", "Lab Fee", "Picnic Fee"]
  schoolDetails:any;
  userDetails:any;
  standard = [];
  sectionObj = {};
  session = [];
  globalSession = "2023-24";
  trendSession = ['2023-24' , '2024-25']
  staticBoards = ['ICSE','CBSE','IB','State Board','Others'];
  staticSections = ["A","B","C","D","E","F","G","H","I","J","K",
  "L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
  staticClasses = ["BABYSTEPS","WALKERS","RUNNERS","FLYERS","PG","KINDERGARTEN","NUR","LKG","UKG","I","II","III","IV","V","VI","VII","VIII","IX","X","XI","XII","ALUMNI"];
  staticStates = ['Andhra Pradesh','Arunachal','Assam','Bihar','Chhattisgarh','Goa','Gujarat','Haryana',
  'Himachal','Jharkhand','Karnataka','Kerala','Madhya Pradesh','Maharashtra','Manipur','Meghalaya','Mizoram',
  'Nagaland','Odisha','Punjab','Rajasthan','Sikkim','Tamil Nadu','Telangana','Tripura','Uttar Pradesh',
  'Uttarakhand','West Bengal','Andaman and Nicobar','Chandigarh','Dadar and Nagar','Daman and Diu','Delhi',
  'Lakshadweep','Puducherry','Jammu and Kashmir','Ladakh']
  stuHeaderList: any;
  availableHeaderList: any;
  stuSearchFields: any;
  reconcilationReport:any;
  transectionList: any;
  categoryWisePaid:any;
  transectionCategoryList: any;
  categoryList: any;
  paymentModeList: any;
  defaulterList: any;
  availableTemplateList: any;
  availableListTransaction: any;
  availableListCategory: any;
  feeTemplate: any;
  availableListMode: any;
  admissionList: any;
  availableListAdmission: any;
  availableListDefaulter : any;
  studentList: any;
 exchange=false;
 previousItemData:any;
  staticCategory=["Stationary","Uniform","Furniture","Science Lab","Kitchen","Electrical Equipment","Computer Lab","Utility"
  ,"Activity Material",
  "Electrical",
  "Electronic", 
  "Marketing and Branding", 
  "Books",
  "Toys",
  "Classroom Items",
  "OIL",
  "Building Infra", "Medical Items",
  "Printing Items",
   "Computer Stationary","Miscellaneous"];
  activeSession: any;
  presentSession: any;
  basicUserDetail: BasicUserDetail;
  basicStudentDetail: BasicStudentDetail;
  basicSchoolDetail: BasicSchoolDetail;
  payObjId: any;
  payObjName: string;
  paySchoolId: any;
  payEnquiryFee: boolean = false;
  enquiryAmount: Number = 0;
  onlineFee: boolean = false;
  constructor(private studentService: StudentService, private userService: AddUserService,
    private schedulingService: SchedulingService, public confirmDialogService: ConfirmDialogService, private roleServ:AccessControlService,
    public allApiService: AllApisService, private masterService: MasterServiceService) {
    //  this.storeData();
    //  this.getFeecCatagory();
  }

  initiateStandard(cb, that) {
    this.studentService.getStandard()
      .subscribe(res => {
        this.standard = res.standardList;
        this.sectionObj = res.sectionListObj;
        cb({ standard: this.standard, sectionObj: this.sectionObj }, that);
      })
  }

  async initiateSession(cb, that) {
    var activeSession = ''
    await this.getActiveSession().then(res => {
      activeSession  = res;
    })
    this.studentService.getSession()
      .subscribe(res => {
        if(res.status) {
          this.session = res.sessionList;
          this.presentSession = res.presentSession;
          //console.log(this.session);
          cb({ session: this.session, activeSession: activeSession }, that);
        }
      })
  }

  initiateUserDetails(cb, that){
    //console.log(localStorage.getItem('token'));
    this.userService.getUserAndSchoolDetails()
    .subscribe(res => {
      this.schoolDetails = res.schoolDetails;
      this.userDetails = res.userDetails;
      this.updateActiveSession();
      cb(res, that);
    })
  }

  fetchUserAndSchoolDetails() {
    this.userService.getUserAndSchoolDetails()
    .subscribe(res => {
      this.schoolDetails = res.schoolDetails;
      this.userDetails = res.userDetails;
      this.updateActiveSession();
      return res;
    })
  }

  async getActiveSession() {
    if(!this.activeSession) {
      if(!this.userDetails || !this.schoolDetails) {
        await this.fetchUserAndSchoolDetails();
      } else {
        await this.updateActiveSession();
      }
    } 
    return this.activeSession;
  }

  updateActiveSession() {
    this.activeSession = this.userDetails.session;
    if(!this.activeSession) {
      this.activeSession = this.schoolDetails.presentSession;
    }
  }

  updateHeaderList(cb, that) {
    if(!this.stuHeaderList || this.stuHeaderList === []) {
      this.masterService.getStudentFields().subscribe(
        res=>{
          console.log("hellllllo update header list ",res)
          this.availableHeaderList = res.availableList;
          this.reconcilationReport =res.reconcilationReport;
          this.stuHeaderList = res.headerList;
          this.stuSearchFields = res.searchFields;
          this.availableTemplateList = res.availableTemplateList;
          this.feeTemplate = res.feeTemplate;
          this.transectionList = res.transectionList;
          this.transectionCategoryList = res.transectionCategoryList;
          this.categoryList = res.categoryList;
          this.paymentModeList = res.paymentModeList;
          this.admissionList = res.admissionList;
          this.defaulterList = res.defaulterList;
          this.availableListTransaction = res.availableListTransaction;
          this.availableListCategory = res.availableListCategory;
          this.availableListMode = res.availableListMode;
          this.availableListAdmission = res.availableListAdmission;
          this.availableListDefaulter = res.availableListDefaulter;
          this.categoryWisePaid = res.categoryWisePaid
          if(cb) {
            cb(res, that);
          }
        }
      );
    } else {
      var res = {}
      res['availableList'] = this.availableHeaderList;
      res['reconcilationReport'] = this.reconcilationReport;
      res['headerList'] = this.stuHeaderList;
      res['searchFields'] = this.stuSearchFields;
      res['availableTemplateList'] = this.availableTemplateList;
      res['feeTemplate'] = this.feeTemplate;
      res['transectionList'] = this.transectionList;
      res['transectionCategoryList'] = this.transectionCategoryList;
      res['categoryWisePaid'] = this.categoryWisePaid;
      res['categoryList'] = this.categoryList;
      res['paymentModeList'] = this.paymentModeList;
      res['admissionList'] = this.admissionList;
      res['defaulterList'] = this.defaulterList;
      res['availableListTransaction'] = this.availableListTransaction;
      res['availableListCategory'] = this.availableListCategory;
      res['availableListMode'] = this.availableListMode;
      res['availableListAdmission'] = this.availableListAdmission;
      res['availableListDefaulter'] = this.availableListDefaulter;
      cb(res, that);
    }
  }
    
  async getPdfDataObj(coloumns: any[], rows: any, title: string, reportName: any, session:any=null,
    startDate: any = null, endDate: any = null) {
    console.log("reportName", session)
    
    if(session==null)
    await this.getActiveSession().then(res => {
      session  = res;
    })
    let logoUrl = this.schoolDetails["logoUrl"];
    // if (!this.schoolDetails["logoUrl"] || this.schoolDetails["logoUrl"] == "" || this.schoolDetails["logoUrl"] == "N/A") {
    //   logoUrl = " ";
    // } else if(this.schoolDetails["logoUrl"] && this.schoolDetails["logoHost"]) {
    //   logoUrl = this.schoolDetails["logoHost"] + this.schoolDetails["logoUrl"];
    // } else {
    //   logoUrl = "https://d3nwkpv8dwssuq.cloudfront.net/school-logo-old" + this.schoolDetails["logoUrl"].replace(/\\/g, '/');;
    // }
    let trtdArr = {
      'thArr': coloumns,//onlyColName,
      'trtdArr': rows,//arrArrData
      'schoolName': this.schoolDetails["name"],//that.schoolName,
      'logoUrl': logoUrl,//that.logoUrl,
      'address': this.schoolDetails["adresss"]["line1"],
      'contactNo': this.schoolDetails.mobileNo,
      'session': session
    }
    if(title) {
      trtdArr['title'] = title;
    }
    if(startDate) {
      trtdArr['startDate'] = startDate;
    }
    if(endDate) {
      trtdArr['endDate'] = endDate;
    }
    if(reportName) {
      trtdArr['reportName'] = reportName;
    }
    return trtdArr;
  }

  has_access(module) {
    if (this.userDetails !== null && this.userDetails !== undefined &&   this.userDetails["roles"] !==undefined && this.userDetails["roles"] !==null && this.userDetails["roles"].length>=0) {
      var role = this.userDetails["roles"][0];
      var unique = this.userDetails["schoolId"].filter((v, i, a) => a.indexOf(v) === i);
      var schoolCount = unique.length;
      //console.log("this roles",role,module);
      if ((role === "Director" || role === "Super Admin") ) {  
        switch (module) {
          case "Add School":
            return true;
          case 'Edit School':
            return true;
          case 'Edit Class Section':
            return false;
          case 'List School':
            return true;
          case 'Edit Fee Categories':
            return true;
          case "Edit Installments":
            return true;
          case 'Add Shop':
            return true;
          case 'User List':
            return true;
          case 'Roles List':
            return true;
          case 'Student Details':
            return false;
          case 'Transport':
            return false;
          case 'Scheduling':
            return false;
          case "Master Setting":
            return true;
          case "General Setting":
            return false;
          default :
            return false;
        }
      } else if (role === "Director") {  
        switch (module) {
          case "Add School":
            return true;
          case 'Edit School':
            return false;
          case 'Edit Class Section':
            return false;
          case 'List School':
            return true;
          case 'Edit Fee Categories':
            return false;
          case 'Add Shop':
            return true;
          case 'User List':
            return true;
          case 'Roles List':
            return true;
          case 'Student Details':
            return false;
          case 'Transport':
            return false;
          case 'Scheduling':
            return false;
          case "Master Setting":
            return true;
          case "General Setting":
            return false;
          default :
            return false;
        }
      }
      else
      if (role === "Super Admin") {
        switch (module) {
          case "Add School":
            return true;
          case 'Edit School':
            return false;
          case 'Edit Class Section':
            return false;
          case 'List School':
            return true;
          case 'Edit Fee Categories':
            return false;
          case 'Add Shop':
            return true;
          case 'User List':
            return true;
          case 'Roles List':
            return true;
          case 'Student Details':
            return false;
          case 'Transport':
            return false;
          case 'Scheduling':
            return false;
          case "Master Setting":
            return true;
          case "General Setting":
            return false;
          default :
            return false;
        }
      }
      else if (role === "Admin") {
        switch (module) {
          case "Add School":
            return false;
          case 'Edit School':
            return true;
          case 'Edit Class Section':
            return true;
          case 'List School':
            return false;
          case 'Edit Fee Categories':
            return true;
          case "Edit Installments":
              return true;
          case 'Add Shop':
            return false;
          case 'Roles List':
            return false;
          case 'Student Details':
            return true;
          case 'Quick Add':
           return false;
          case 'Export':
            return false;
          case 'Transport':
            return true;
          case 'Scheduling':
            return true;
          case "Master Setting":
            return false;
          case "General Setting":
            return true;
          default :
            return true;
        }

      }
      else if (role === "Accountant") {
        switch (module) {
          case "Add School":
            return false;
          case 'Edit School':
            return true;
          case 'Edit Class Section':
            return true;
          case 'List School':
            return false;
          case 'Edit Fee Categories':
            return true;
          case 'Add Shop':
            return false;
          case 'User List':
            return false;
          case 'Roles List':
              return false;
          case 'Student Details':
            return true;
          case 'Quick Add':
            return false;
          case 'Export':
            return false;
          case 'Transport':
            return true;
          case 'Scheduling':
            return true;
          case "Master Setting":
            return false;
          case "General Setting":
            return false;
          default :
            return true;
        }
    }
  }

  }

  storeData() {
    ////console.log("data ja rha hai");
    let allUserInstallments: Array<String> = [];
    localStorage.setItem("allUserInstallments", JSON.stringify(allUserInstallments));

    let allClasses: Array<String> = ["Lkg", "UKG", "I", "II", "III", "IV", "V"]
    localStorage.setItem('allClasses', JSON.stringify(allClasses));

    let allSection: Array<String> = ["A", "B", "C", "D", "E", "F"]
    localStorage.setItem('allSection', JSON.stringify(allSection));
  }

  destroyVariables() {
    this.schoolDetails = null;
    this.userDetails = null;
    this.standard = null;
    this.sectionObj = null;
    this.session = null;

    //console.log("userdetails ", this.userDetails);
  }
  getFeecCatagory() {
    this.schedulingService.getFeeCategory().subscribe(
      res => { ////console.log("9999999999999",res);
        // let allUserCatagories:Array<String>=["Admission Fee", "Tution Fee", "Lab Fee", "Picnic Fee"]

        // Extracting feeName values as an array
        var newData = res
        var feeNames = newData.data.map(function(item) {
       return item.feeName;
      });

      // Saving feeNames array to local storage
      localStorage.setItem('allUserCatagories', JSON.stringify(feeNames));

        // localStorage.setItem("allUserCatagories", JSON.stringify(arrayDD));
      },
      err => { //console.log("error fatching fee catagory"); 
    }
    )
  }

  updateFeeTemplate(feeTemplate:any) {
    return this.masterService.updateFeeTemplate(feeTemplate);
  }


}
