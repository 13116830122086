import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { superAdminService } from "../dash-content/dash-content.service";
import { AddUserService } from "../UserDetailsO/AddUser/adduser.service";
import { Chart } from "chart.js";
import { OneStorage } from "src/app/GlobalDataStorage/oneStorage.service";

declare var $: any;

@Component({
  selector: "app-blank-content",
  templateUrl: "./blank-content.component.html",
  styleUrls: ["./blank-content.component.scss"],
})
export class BlankContentComponent implements OnInit {
  coloumns = [
    {
      data: "role",
      title: "Role",
      defaultContent: "<i>-</i>",
    },
    {
      data: "count",
      title: "Count",
      defaultContent: "<i>-</i>",
    },
  ];
  totalCollection: any;
  weeklyTransaction: any;
  defaulterListCount: number;
  totalDefaulterAMount: number;
  feeCategoryArray: any;
  feeInstallment: any;
  selectedInstallment = "Select All";
  selectedFeeCategory = "Select All";
  selectedClass = "Select All";
  selectedSection = "Select All";

  student: number;
  teacher = 0;
  dailyTransaction: number;
  user: number;
  monthlyTransaction: number;
  schoolId = "all";
  schoolCount: number;
  schoolList = [];
  dailyCollection: number;
  monthlyCollection: number;

  dropdownVisible2: boolean = false;
  dropdownVisible3: boolean = false;
  canvas: any;
  ctx: any;
  @ViewChild("myChart", { static: false }) myChart: ElementRef;
  @ViewChild("pieChart", { static: false }) myChart1: ElementRef;
  @ViewChild("myChart2", { static: false }) myChart2: ElementRef;
  @ViewChild("pieChart1", { static: false }) myChart3: ElementRef;
  @ViewChild("myChart4", { static: false }) myChart4: ElementRef;
  @ViewChild("myChart5", { static: false }) myChart5: ElementRef;
  @ViewChild("myChart6", { static: false }) myChart6: ElementRef;
  @ViewChild("myChart7", { static: false }) myChart7: ElementRef;
  myCharts: Chart;
  myCharts1: Chart;
  myCharts2: Chart;
  myCharts3: Chart;
  myCharts4: Chart;
  myCharts5: Chart;
  roleCountsMap: any;

  // new dashboard
  amount: Number = 200;
  dropdownVisible: boolean = false;
  allStandard: any[];
  sectionObj: {};
  allSection: any;
  barChartData: {};
  chartOptions: {};

  classTotals: { class: string; totalAmount: number; totalStudents: number }[] =
    [];

  public pieChartLabels = [];
  public pieChartData = [];
  public pieChartType: string = "doughnut";
  public pieChartLabelsFine = [];
  public pieChartDataFine = [];
  showFinePieChart: boolean = false;
  showDefaulterChart: boolean = false;

  hexColors: string[] = [
    "#F88919",
    "#4478A1",
    "#E8D1BA",
    "#EAC8A6",
    "#004B85",
    "#F6912C",
    "#16598E",
    "#F49B41",
    "#2D6997",
    "#F2A455",
    "#F0AD69",
    "#5B88AB",
    "#EEB67D",
    "#E6DACE",
    "#7196B4",
    "#ECBF92",
    "#89A7BE",
    "#9FB5C7",
    "#CDD4DB",
  ];

  constructor(
    private globals: OneStorage,
    private serv: superAdminService,
    private serv2: AddUserService
  ) {}

  private callback1(res: {}, that: any) {
    that.allStandard = res["standard"];
    that.sectionObj = res["sectionObj"];
    if (that.allStandard[0]) {
      // that.selectedClass = that.allStandard[0];
      that.allSection = that.sectionObj[that.selectedClass];
      // that.selectedSection = that.allSection[0];
    }
  }

  onClassChange(std: string) {
    this.allSection = this.sectionObj[std];
    this.reconciliationInstallmentWise();
  }

  ngOnInit() {
    this.showFinePieChart = false;
    this.showDefaulterChart = false;
    this.detailCount(this.schoolId);
    this.getSchoolList();
    // this.defaulterCount();
    // this.reconciliationInstallmentWise();
    this.modeWiseCollection();
    this.getConcessionAmount();
    this.getClassWiseFineCollected();

    // this.collectionTrend();
    // this.concessionTrend();
    // this.fineTrend();

    if (this.globals.standard === null || this.globals.standard.length === 0) {
      this.globals.initiateStandard(this.callback1, this);
    } else {
      this.allStandard = this.globals.standard;
      this.sectionObj = this.globals.sectionObj;
      if (this.allStandard[0]) {
        // this.selectedClass = this.allStandard[0];
        this.allSection = this.sectionObj[this.selectedClass];
        // this.selectedSection = this.allSection[0];
      }
    }
  }

  ngAfterViewInit() {
    this.reconciliationInstallmentWise();
    this.collectionTrend();
    this.concessionTrend();
    this.fineTrend();
    // let data = [
    //   { x: 1, y: 2 },
    //   { x: 2500, y: 2.5 },
    //   { x: 3000, y: 5 },
    //   { x: 3400, y: 4.75 },
    //   { x: 3600, y: 4.75 },
    //   { x: 5200, y: 6 },
    //   { x: 6000, y: 9 },
    //   { x: 7100, y: 6 },
    //   { x: 8100, y: 9 },
    // ];
    // this.renderGraph(data);
  }

  destroyGraph() {
    if (this.myCharts) {
      this.myCharts.destroy();
    }
  }

  renderGraph() {
    this.destroyGraph();

    this.canvas = this.myChart.nativeElement;
    this.ctx = this.canvas.getContext("2d");

    this.myCharts = new Chart(this.ctx, {
      type: "bar",
      data: this.barChartData,
      options: this.chartOptions,
    });
  }

  renderPieChart() {
    this.canvas = this.myChart1.nativeElement;
    this.ctx = this.canvas.getContext("2d");

    const data = {
      labels: this.pieChartLabels,
      datasets: [
        {
          data: this.pieChartData,
          backgroundColor: this.hexColors,
        },
      ],
    };

    this.myCharts1 = new Chart(this.ctx, {
      type: this.pieChartType,
      data: data,
    });
  }

  renderPieChart1() {
    this.canvas = this.myChart3.nativeElement;
    this.ctx = this.canvas.getContext("2d");

    const data = {
      labels: this.pieChartLabelsFine,
      datasets: [
        {
          label: "Dataset 1",
          data: this.pieChartDataFine,
          backgroundColor: this.hexColors,
        },
      ],
      options: {
        title: {
          display: true,
          text: "SessionWise Monthly Fine Collection",
          fontSize: 20,
        },
      },
    };

    this.myCharts3 = new Chart(this.ctx, {
      type: this.pieChartType,
      data: data,
    });
  }

  renderChartConc(data) {
    this.canvas = this.myChart2.nativeElement;
    this.ctx = this.canvas.getContext("2d");

    const studentCounts = data.map((item) => item.studentCount);
    var myChart = new Chart(this.ctx, {
      type: "bar",
      data: {
        labels: data.map((data) => data.feeName),
        datasets: [
          {
            label: "Concession Amount",
            data: data.map((data) => -data.concessionAmount),
            backgroundColor: "#f77b18",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
            barThickness: 40,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontFamily: "'Noto Serif', serif;",
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const datasetLabel =
                data.datasets[tooltipItem.datasetIndex].label || "";
              const amount = tooltipItem.yLabel;
              const studentCount = studentCounts[tooltipItem.index];

              return `${datasetLabel}: ${amount} (Students: ${studentCount})`;
            },
          },
        },
      },
    });
  }

  renderCollectionTrendChart(data) {
    this.canvas = this.myChart5.nativeElement;
    this.ctx = this.canvas.getContext("2d");

    const months: any = [...new Set(data.map((entry) => entry.month))]; // Get unique months

    console.log("collection data" , data , months ,this.feeInstallment)
    const sortedData = months.sort((a, b) => {
      return this.feeInstallment.indexOf(a) - this.feeInstallment.indexOf(b);
    });

    const sessions: any = [...new Set(data.map((entry) => entry.session))]; // Get unique sessions

    const backgroundColors = ["#005c95", "#f77b18"]; // Custom background colors for sessions
    const borderColors = ["rgba(243, 213, 145, 1)", "rgba(29, 123, 197, 1)"]; // Custom border colors for sessions

    const datasets = sessions.map((session, index) => {
      const sessionData = data.filter((entry) => entry.session === session);

      const payables = sortedData.map((month) => {
        const monthData = sessionData.find((entry) => entry.month === month);

        return monthData ? monthData.totalPayable : 0;
      });

      return {
        label: session,
        data: payables,
        backgroundColor: backgroundColors[index],
        borderColor: borderColors[index],
        borderWidth: 1,
      };
    });

    var myChart = new Chart(this.ctx, {
      type: "bar",
      data: {
        labels: sortedData,
        datasets: datasets,
      },
      options: {
        layout: {
          padding: {
            left: 20,
          },
        },
        title: {
          display: true,
          text: "SessionWise Monthly Fee Collection",
          fontSize: 20,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                callback: function (value, index, values) {
                  // Check if the value is greater than or equal to 1 crore
                  value = Number(value);
                  if (value >= 10000000) {
                    return (value / 10000000).toLocaleString("en-IN") + " Cr";
                  }
                  // Check if the value is greater than or equal to 1 lakh
                  else if (value >= 100000) {
                    return (value / 100000).toLocaleString("en-IN") + " Lc";
                  } else if (value >= 1000) {
                    return (value / 1000).toLocaleString("en-IN") + " K";
                  }
                  // Use default formatting for values less than 1 lakh
                  else {
                    return value.toLocaleString("en-IN");
                  }
                },
                beginAtZero: true,
                fontFamily: "'Noto Serif', serif;",
              },
            },
          ],
        },
      },
    });
  }

  renderConcessionTrendChart(data) {
    this.canvas = this.myChart6.nativeElement;
    this.ctx = this.canvas.getContext("2d");

    const months: any = [...new Set(data.map((entry) => entry.month))]; // Get unique months

    const sortedData = months.sort((a, b) => {
      return this.feeInstallment.indexOf(a) - this.feeInstallment.indexOf(b);
    });

    const sessions: any = [...new Set(data.map((entry) => entry.session))]; // Get unique sessions

    const backgroundColors = ["#005c95", "#f77b18"]; // Custom background colors for sessions
    const borderColors = ["rgba(243, 213, 145, 1)", "rgba(29, 123, 197, 1)"]; // Custom border colors for sessions

    const datasets = sessions.map((session, index) => {
      const sessionData = data.filter((entry) => entry.session === session);

      const payables = sortedData.map((month) => {
        const monthData = sessionData.find((entry) => entry.month === month);

        return monthData ? monthData.totalPayable : 0;
      });

      console.log("bharat", payables);

      return {
        label: session,
        data: payables,
        backgroundColor: backgroundColors[index],
        borderColor: borderColors[index],
        borderWidth: 1,
      };
    });

    var myChart = new Chart(this.ctx, {
      type: "bar",
      data: {
        labels: sortedData,
        datasets: datasets,
      },
      options: {
        layout: {
          padding: {
            left: 20,
          },
        },
        title: {
          display: true,
          text: "SessionWise Monthly Concession Collection",
          fontSize: 20,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                callback: function (value, index, values) {
                  // Check if the value is greater than or equal to 1 crore
                  value = Number(value);
                  if (value >= 10000000) {
                    return (value / 10000000).toLocaleString("en-IN") + " Cr";
                  }
                  // Check if the value is greater than or equal to 1 lakh
                  else if (value >= 100000) {
                    return (value / 100000).toLocaleString("en-IN") + " Lc";
                  } else if (value >= 1000) {
                    return (value / 1000).toLocaleString("en-IN") + " K";
                  }
                  // Use default formatting for values less than 1 lakh
                  else {
                    return value.toLocaleString("en-IN");
                  }
                },
                beginAtZero: true,
                fontFamily: "'Noto Serif', serif;",
              },
            },
          ],
        },
      },
    });
  }

  renderFineTrendChart(data) {
    this.canvas = this.myChart7.nativeElement;
    this.ctx = this.canvas.getContext("2d");

    const months: any = [...new Set(data.map((entry) => entry.month))]; // Get unique months

    const sortedData = months.sort((a, b) => {
      return this.feeInstallment.indexOf(a) - this.feeInstallment.indexOf(b);
    });

    const sessions: any = [...new Set(data.map((entry) => entry.session))]; // Get unique sessions

    const backgroundColors = ["#005c95", "#f77b18"]; // Custom background colors for sessions
    const borderColors = ["rgba(243, 213, 145, 1)", "rgba(29, 123, 197, 1)"]; // Custom border colors for sessions

    const datasets = sessions.map((session, index) => {
      const sessionData = data.filter((entry) => entry.session === session);

      const payables = sortedData.map((month) => {
        const monthData = sessionData.find((entry) => entry.month === month);

        return monthData ? monthData.totalPayable : 0;
      });

      return {
        label: session,
        data: payables,
        backgroundColor: backgroundColors[index],
        borderColor: borderColors[index],
        borderWidth: 1,
        // barThickness: 80,
      };
    });

    var myChart = new Chart(this.ctx, {
      type: "bar",
      data: {
        labels: sortedData,
        datasets: datasets,
      },
      options: {
        layout: {
          padding: {
            left: 20,
          },
        },
        title: {
          display: true,
          text: "SessionWise Monthly Fine Collection",
          fontSize: 20,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                callback: function (value, index, values) {
                  // Check if the value is greater than or equal to 1 crore
                  value = Number(value);
                  if (value >= 10000000) {
                    return (value / 10000000).toLocaleString("en-IN") + " Cr";
                  }
                  // Check if the value is greater than or equal to 1 lakh
                  else if (value >= 100000) {
                    return (value / 100000).toLocaleString("en-IN") + " Lc";
                  } else if (value >= 1000) {
                    return (value / 1000).toLocaleString("en-IN") + " K";
                  }
                  // Use default formatting for values less than 1 lakh
                  else {
                    return value.toLocaleString("en-IN");
                  }
                },
                beginAtZero: true,
                fontFamily: "'Noto Serif', serif;",
              },
            },
          ],
        },
      },
    });
  }

  renderChartDefaulter(data) {
    this.canvas = this.myChart4.nativeElement;
    this.ctx = this.canvas.getContext("2d");

    const studentCounts = data.map((item) => item.totalStudents);
    var myChart = new Chart(this.ctx, {
      type: "bar",
      data: {
        labels: data.map((data) => data.class),
        datasets: [
          {
            label: "Defaulter Amount",
            data: data.map((data) => data.totalAmount),
            backgroundColor: "#B31312",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
            barThickness: 40,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontFamily: "'Noto Serif', serif;",
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const datasetLabel =
                data.datasets[tooltipItem.datasetIndex].label || "";
              const amount = tooltipItem.yLabel;
              const studentCount = studentCounts[tooltipItem.index];

              return `${datasetLabel}: ${amount} (Students: ${studentCount})`;
            },
          },
        },
      },
    });
  }

  getClassWiseFineCollected() {
    this.serv.getFineData(this.globals.globalSession).subscribe((res) => {
      // console.log("Fine response class wise", res.data)

      res.data.forEach((elem) => {
        this.pieChartLabelsFine.push(elem._id);
        this.pieChartDataFine.push(elem.totalFineCollected);
      });

      const sum = this.pieChartDataFine.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );

      if (this.pieChartDataFine.length == 0 || sum == 0) {
        this.showFinePieChart = true;
      }

      this.renderPieChart1();
    });
  }

  getConcessionAmount() {
    this.serv
      .getAllConcessionAmount(this.globals.globalSession)
      .subscribe((res) => {
        console.log("concession response", res.data);

        this.renderChartConc(res.data);
      });
  }

  reconciliationInstallmentWise() {
    this.barChartData = {};
    this.serv
      .getReconData(
        this.globals.globalSession,
        this.selectedClass,
        this.selectedSection
      )
      .subscribe((res) => {
        res.paidArray.sort((a, b) => {
          let installmentA = a._id.month;
          let installmentB = b._id.month;
          let indexA = this.feeInstallment.indexOf(installmentA);
          let indexB = this.feeInstallment.indexOf(installmentB);
          return indexA - indexB;
        });

        console.log("response for collection recon", res);

        const datasets = [
          {
            label: "Total Paid Amount",
            data: res.paidArray.map((entry) => entry.TotalPaidAmount),
            backgroundColor: "#004B85",
          },
          {
            label: "Total Concession",
            data: res.paidArray.map((entry) => -entry.TotalConcession),
            backgroundColor: "#EEB67D",
          },
          {
            label: "Total Transport Amount",
            data: res.paidArray.map((entry) => entry.TotalTransportAmount),
            backgroundColor: "#7196B4",
          },
          {
            label: "Total Fine",
            data: res.paidArray.map((entry) => entry.TotalFine),
            backgroundColor: "#F6912C",
          },
        ];

        const filteredDatasets = datasets.filter((dataset) => {
          return dataset.data.some((value) => value !== 0);
        });

        this.barChartData = {
          labels: res.paidArray.map((entry) => entry._id.month),
          datasets: filteredDatasets,
        };

        this.chartOptions = {
          layout: {
            padding: {
              left: 20,
            },
          },
          responsive: true,
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Total Collection Chart",
            fontSize: 20,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  callback: function (value, index, values) {
                    // Check if the value is greater than or equal to 1 crore
                    value = Number(value);
                    if (value >= 10000000) {
                      return (value / 10000000).toLocaleString("en-IN") + " Cr";
                    }
                    // Check if the value is greater than or equal to 1 lakh
                    else if (value >= 100000) {
                      return (value / 100000).toLocaleString("en-IN") + " Lc";
                    } else if (value >= 1000) {
                      return (value / 1000).toLocaleString("en-IN") + " K";
                    }
                    // Use default formatting for values less than 1 lakh
                    else {
                      return value.toLocaleString("en-IN");
                    }
                  },
                  beginAtZero: true,
                  fontFamily: "'Noto Serif', serif;",
                },
              },
            ],
          },
        };

        this.renderGraph();
      });
  }

  modeWiseCollection() {
    this.serv.modeWiseCol(this.globals.globalSession).subscribe((res) => {
      // console.log("hua pie data" , res)
      res.data.forEach((elem) => {
        this.pieChartLabels.push(elem._id);
        this.pieChartData.push(elem.totalPaidAmount);
      });

      this.renderPieChart();
    });
  }

  // defaulterCount() {
  //   this.serv.getDefaulterDetails().subscribe((res) => {
  //     // console.log("defaulter response", res)
  //     this.calculateClassTotals(res.defaulterList)
  //   });
  // }

  calculateClassTotals(studentData) {
    studentData.pop();
    for (const student of studentData) {
      const classTotal = this.classTotals.find(
        (ct) => ct.class === student.class
      );

      if (classTotal) {
        classTotal.totalAmount += student.dueAmount;
        classTotal.totalStudents++;
      } else {
        this.classTotals.push({
          class: student.class,
          totalAmount: student.dueAmount,
          totalStudents: 1,
        });
      }
    }
    console.log("class totals", this.classTotals);

    if (this.classTotals.length == 0) {
      this.showDefaulterChart = true;
    }
    // this.renderChartDefaulter(this.classTotals)
  }

  detailCount(schoolId: any) {
    this.serv.getDetails1().subscribe((res) => {
      var details = res;
      this.roleCountsMap = details.userRoles;
      this.user = details.user;
      this.dailyTransaction = details.dailyTransaction;
      this.monthlyTransaction = details.monthlyTransaction;
      this.weeklyTransaction = details.weeklyTransaction;
      this.totalCollection = details.overallCollection;
    });
  }
  getSchoolList() {
    this.serv2.getUserSchoolNames1(this.globals.globalSession).subscribe((res) => {
      console.log("what school repsonse" , res)
      // this.schoolList = res;
      // this.feeCategoryArray = res[0].feeCategory;
      let installment = [];
      res.installment.forEach((element) => {
        if (element.session == this.globals.globalSession) {
          installment = element.installment;
        }
      });
      this.feeInstallment = installment.map((month) => month.title);
    });
  }

  collectionTrend() {
    this.serv.collectionTrendApi(this.globals.trendSession).subscribe((res) => {
      // console.log("response trend colection", res)
      this.renderCollectionTrendChart(res.data);
    });
  }

  concessionTrend() {
    this.serv.concessionTrendApi(this.globals.trendSession).subscribe((res) => {
      console.log("response trend concession", res);
      this.renderConcessionTrendChart(res.data);
    });
  }

  fineTrend() {
    this.serv.fineTrendApi(this.globals.trendSession).subscribe((res) => {
      console.log("response trend fine", res);
      this.renderFineTrendChart(res.data);
    });
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible;
  }

  toggleDropdown2() {
    this.dropdownVisible2 = !this.dropdownVisible2;
  }

  toggleDropdown3() {
    this.dropdownVisible3 = !this.dropdownVisible3;
  }

  // populateTable1() {
  //   let that = this;
  //   if ($.fn.DataTable.isDataTable(".example1")) {
  //     $(".example1").DataTable().clear().destroy();
  //   }
  //   $(".showhideTable").css("display", "block");

  //   const convertedData = this.roleCountsMap
  //     .map(([role, count]) => {
  //       if (role === "Parent") {
  //         return;
  //       }
  //       return { role, count };
  //     })
  //     .filter(Boolean);
  //   const example = $(".example1").DataTable({
  //     data: convertedData,
  //     columns: this.coloumns,
  //     columnDefs: [],
  //     dom: "rtp",
  //     lengthMenu: [
  //       [5, 10, 25, 50, -1],
  //       [5, 10, 25, 50, "All"],
  //     ],
  //   });
  // }
}
